import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';

const ArticlePage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, excerpt, html } = markdownRemark;
  const pageUrl = `https://way-truth-life.org${frontmatter.slug}`;
  return (
    <Layout over={false}>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description || excerpt} />
        <meta
          name="keywords"
          content={`статьи, ${frontmatter.tags.join(
            ', ',
          )}, путь истина и жизнь`}
        />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta
          property="og:description"
          content={frontmatter.description || excerpt}
        />
        {!!frontmatter.cover && (
          <meta property="og:image" content={frontmatter.cover} />
        )}
      </Helmet>
      <div id="content">
        <div className="container">
          <div className="layout with-right-sidebar">
            <div className="row">
              <div className="col-md-9">
                <div className="main-content">
                  <article className="article">
                    <h1 className="article-title">{frontmatter.title}</h1>
                    <ul className="article-meta">
                      <li>
                        <span className="article-meta-author article-meta-item">
                          {frontmatter.author}
                        </span>
                      </li>
                    </ul>
                    <div className="article-content">
                      <div dangerouslySetInnerHTML={{ __html: html }}></div>
                      {/* {frontmatter.tags.length > 0 && (
                        <div className="article-tags">
                          {' '}
                          Tags:
                          {frontmatter.tags.map((tag) => (
                            <a className="article-tag" href="#">
                              {tag}
                            </a>
                          ))}
                        </div>
                      )} */}
                      <div className="social-likes social-share social-likes_visible social-likes_ready">
                        <a
                          href={`https://www.facebook.com/sharer.php?u=${encodeURI(
                            pageUrl,
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                          className="social-likes__widget social-likes__widget_facebook"
                          title="Share link on Facebook">
                          <span className="social-likes__button social-likes__button_facebook">
                            <span className="social-likes__icon social-likes__icon_facebook"></span>
                            Facebook
                          </span>
                          <span className="social-likes__counter social-likes__counter_facebook social-likes__counter_empty"></span>
                        </a>
                        <a
                          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                            frontmatter.title,
                          )}&url=${encodeURI(pageUrl)}`}
                          target="_blank"
                          rel="noreferrer"
                          className="social-likes__widget social-likes__widget_twitter"
                          title="Share link on Twitter">
                          <span className="social-likes__button social-likes__button_twitter">
                            <span className="social-likes__icon social-likes__icon_twitter"></span>
                            Twitter
                          </span>
                          <span className="social-likes__counter social-likes__counter_twitter social-likes__counter_empty"></span>
                        </a>
                      </div>
                    </div>
                    {/* {!!(previous || next) && (
                      <div class="article-navigation">
                        {!!previous && (
                          <Link
                            to={previous.frontmatter.slug}
                            className="article-navigation-prev">
                            Предыдущая статья
                          </Link>
                        )}
                        {!!next && (
                          <Link
                            to={next.frontmatter.slug}
                            class="article-navigation-next">
                            Следующая статья
                          </Link>
                        )}
                      </div>
                    )} */}
                  </article>
                </div>
              </div>
              <div className="col-md-3 hidden-sm hidden-xs">
                {/* <div className="sidebar">
                  <div className="widget">
                    <h3 className="widget-title">Tags</h3>
                    <ul className="tags">
                      {frontmatter.tags.map((tag) => (
                        <li className="tag-item">
                          <a href={`/articles?tag=${tag}`}>{tag}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        slug
        title
        tags
        author
        description
      }
    }
  }
`;

export default ArticlePage;
